@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body,
html {
  font-family: 'Poppins', sans-serif !important;
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.active {
  color: #1479bd;
}

.themeColor {
  background-color: #1479bd;
}

.main {
  width: calc(100% - 240px);
}

.childH {
  height: calc(100% - 64px);
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: 'gray'
}

.label {
  color: #8F95B2
}

.values {
  color: #131313
}

.list {
  max-height: 384px;
}

.ambassadorList {
  max-height: 384px;
}

.tableHeight {
  max-height: 670px;
}

.searchBar {
  width: 20%;
}

.breadcrumb {
  font-size: 20px;
  line-height: 28px;
}

.ambassadorCard {
  margin-top: 40px;
}

.ambassadorCardGap {
  margin-top: 20px;
}

.ambassadorPanel {
  margin-top: 20px;
}

.wallet {
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .childH {
    height: calc(100% - 56px);
  }

  .list {
    max-height: 200px;
  }

  .ambassadorList {
    max-height: 150px;
  }

  .tableHeight {
    max-height: 400px;
  }

  .searchBar {
    width: 200px;
  }

  .breadcrumb {
    font-size: 16px;
    line-height: 20px;
  }

  .ambassadorCard {
    margin-top: 10px;
  }

  .ambassadorPanel {
    margin-top: 5px;
  }

  .ambassadorCardGap {
    margin-top: 0px;
  }

  /* .wallet{
    flex-direction: row;
  } */
}


/* .formcard {
  border-radius: 4.511px;
  background: rgba(255, 255, 255, 0.83);
  box-shadow: 0.99px 3.00719px 18.04317px 0px rgba(0, 0, 0, 0.13);
} */



/* homescreen */

.homeScreen {
  background-image: url('../src/assets/Rectangle\ 238.png');
  background-size: cover;
}

.css-x5wxbh-MuiPaper-root-MuiAppBar-root{
  background-color: #fff !important;
}

.testemonials::-webkit-scrollbar{
  display: none;
}